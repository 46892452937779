import React from "react";
import { useNavigate } from 'react-router-dom';


function getAverage(array, prop, fixed = 1) {
  const avg = array.map((x) => x[prop]).reduce((a, b) => a + b) / array.length;
  return fixed ? avg.toFixed(2) : avg;
}

function getDiffFirstAndLast(array, prop, fixed = 1) {
  const avg = Math.abs(array[0][prop] - array[array.length - 1][prop]);
  return fixed ? avg.toFixed(2) : avg;
}
function TickerRow(props) {
  const ticker = props.ticker;
  const timeframe = props.timeframe;
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => {
        navigate(`/data/${ticker.symbol}/${timeframe}`);
      }}
    >
      {props.showSymbol && <td>{ticker.symbol}</td>}
      {props.showClose && <td>{(ticker.close ?? 0).toFixed(2)}</td>}
      {props.showVolume && <td>{(ticker.volume ?? 0).toFixed(2)}</td>}
      {props.optional && <td>{(ticker.optional ?? 0).toFixed(2)}</td>}
      {props.diffFieldEnabled && (
        <td>{((ticker.close ?? 0) - (ticker.avg ?? 0)).toFixed(2)}</td>
      )}
      {props.showTime && (
        <td>
          {new Date(Date.parse(ticker.timeUtc)).toUTCString("en-US", {
            dateStyle: "full",
          })}
        </td>
      )}
    </tr>
  );
}

function SummaryTickerRow(props) {
  const tickers = props.tickers;
  return (
    <tr>
      {props.showSymbol && <td>Average</td>}
      {props.showClose && <td>{getAverage(tickers, "close")}</td>}
      {props.showVolume && <td>{getAverage(tickers, "volume", false)}</td>}
      {props.optional && <td>Diff.</td>}
      {props.optional && <td>{getDiffFirstAndLast(tickers, "optional")}</td>}
      {props.diffFieldEnabled && <td></td>}
      {props.showTime && <td></td>}
    </tr>
  );
}


function TickerTable(props) {

  const tickers = props.tickers;
  const symbol = props.symbol ?? "Symbol";
  const close = props.close ?? "Price";
  const volume = props.volume ?? "Volume";
  const time = props.time ?? "Time";
  const timeframe = props.timeframe ?? 1440;
  const optionalFieldEnabled = props.tickers.some(x => x.optional > 0) && props.optional;
  const diffFieldEnabled = props.diffFieldEnabled;

  const showSymbol = props.showSymbol !== false;
  const showClose = props.showClose !== false;
  const showVolume = props.showVolume !== false;
  const showTime = props.showTime !== false;

  if (!tickers || !tickers.length) return <div>Loading ...</div>;
  return (
    <table className={"table" + (tickers.length > 15 ? " table-sm" : "")}>
      <thead>
        <tr>
          {showSymbol && <th>{symbol}</th>}
          {showClose && <th>{close}</th>}
          {showVolume && <th>{volume}</th>}
          {props.optional && <th>{props.optional}</th>}
          {diffFieldEnabled && <th>Dif 1</th>}
          {showTime && <th>{time}</th>}
        </tr>
      </thead>
      <tbody>
        {tickers.map((ticker) => {
          return <TickerRow
            ticker={ticker}
            key={ticker.symbol + ticker.timeUtc}
            optional={optionalFieldEnabled}
            diffFieldEnabled={diffFieldEnabled}
            timeframe={timeframe}
            showSymbol={showSymbol}
            showClose={showClose}
            showVolume={showVolume}
            showTime={showTime}
          ></TickerRow>;
        })}
        <SummaryTickerRow
          tickers={tickers}
          optional={props.optional}
          diffFieldEnabled={diffFieldEnabled}
          showSymbol={showSymbol}
          showClose={showClose}
          showVolume={showVolume}
          showTime={showTime}
        ></SummaryTickerRow>
      </tbody>
    </table>
  );
}

export default TickerTable;
