import React from 'react';

const ChiwanButton = ({ rol,label, onClick }) => {
  return (
    <button
      className={`btn btn-${rol}`}
      style={{
        marginTop: "0.5rem"
      }}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default ChiwanButton;