import React, { useState , useEffect} from "react";
import { useParams, useNavigate } from 'react-router-dom';
import InputSimple from "./SharedComponents/InputSimpleComponent";
import ChiwanButton from "./SharedComponents/ChiwanButtonComponent";
import TickerTable from "./TickerComponents/TickerTableComponent";



function Data() {
  // Declare a new state variable, which we'll call "count"
  const { ticker, timeframe } = useParams();
  const navigate = useNavigate();

  const [selectedTicker, setTicker] = useState(ticker || "");
  const [selectedTimeFrame, setTimeFrameMinutes] = useState(timeframe || "");
  const [cache, setCache] = useState(null);
  useEffect(() => {
    setTicker(ticker);
    setTimeFrameMinutes(timeframe);
    fetch(`/Data/TickerCache?ticker=${selectedTicker}&timeframe=${selectedTimeFrame}`)
    .then((result) => result.json())
    .then((output) => {
      console.log(output);
      setCache(output);
    })
  }, [ticker,timeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClick() {
    navigate(`/data/${selectedTicker}/${selectedTimeFrame}`);
  }

  return (
    <div className="row">

      <div className="col-4">
        <h3>Stored data for: </h3>
        <InputSimple name="Tickers (Separate by commas)" type="text" value={selectedTicker} setter={setTicker}></InputSimple>
        <InputSimple name="Timeframe (In Minutes 1, 60 or 1440)" type="number" value={selectedTimeFrame} setter={setTimeFrameMinutes}></InputSimple>
        <ChiwanButton onClick={handleClick} label="Get Data" rol="primary"/>
      </div>
      <div className="col-8">
        {
          cache && <TickerTable tickers={cache}></TickerTable>
        }
        
      </div>
    </div>
  );
}
export default Data;
