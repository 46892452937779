import React, { useState } from "react";
import TickerTable from "./TickerComponents/TickerTableComponent";
import PriceRangePicker from "./SharedComponents/PriceRangePickerComponent";

function PullTickersFiltered() {
  // Declare a new state variable, which we'll call "count"
  const [clean, setClean] = useState(true);
  const [values, setValues] = useState("");
  const [priceFrom, setPriceFrom] = React.useState(4);
  const [priceTo, setPriceTo] = React.useState(5);
  const [volumeFrom, setVolumeFrom] = React.useState(0);
  const [volumeTo, setVolumeTo] = React.useState(99999999);
  const [market, setMarket] = React.useState(4);
  const options = { All: 0, NyseMkt: 1, NyseArca: 2, Nyse: 3, Nasdaq: 4, Bats: 5, Amex: 6, Arca: 7, Iex: 8, Otc: 9 };
  function getTickerValues() {
    setClean(false);
    const marketString = Number(market) !== 0 ? `&market=${market}` : "";
    fetch(`/Alpaca/FilteredTickers?volumeFrom=${volumeFrom}&volumeTo=${volumeTo}&priceFrom=${priceFrom}&priceTo=${priceTo}${marketString}`)
      .then((result) => result.json())
      .then((output) => {
        console.log("Output: ", output);
        setValues(output);
      })
      .catch((err) => console.error(err));
  }
  return (
    <div>
      <div className="row">
        <div className="col-4">
          <label>Price </label>
          <PriceRangePicker a={priceFrom} aSetter={setPriceFrom} b={priceTo} bSetter={setPriceTo}></PriceRangePicker>

          <label>Volume </label>
          <PriceRangePicker a={volumeFrom} aSetter={setVolumeFrom} b={volumeTo} bSetter={setVolumeTo}></PriceRangePicker>
          <label>Market:</label>
          <select className="form-control" onChange={(e) => setMarket(e.target.value)} value={market}>
            {Object.entries(options).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>
          <br />
          <button className="btn btn-primary" type="button" onClick={getTickerValues}>
            Get filtered tickers
          </button>
        </div>
        <div className="col-8">
          {!clean && (
            <div>
              <h4>
                Results between {priceFrom} and {priceTo}
              </h4>
              <TickerTable tickers={values} volume="Avg. Volume" time="Data since"></TickerTable>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
function PullTickers() {
  return <PullTickersFiltered></PullTickersFiltered>;
}
export default PullTickers;
