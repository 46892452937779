import React from "react";

function PriceRangePicker(props) {
    return (
      <div className="row rangeValuesPicker">
        <div className="col-5">
          <input
            className="form-control"
            type="number"
            onChange={(e) => {
              props.aSetter(e.target.value);
            }}
            value={props.a}
          />
        </div>
        <div className="col-2"> to </div>
        <div className="col-5">
          <input
            className="form-control"
            type="number"
            onChange={(e) => {
              props.bSetter(e.target.value);
            }}
            value={props.b}
          />
        </div>
      </div>
    );
  }


  export default PriceRangePicker;