import React, { Component, useState } from "react";
import InputSimple from "./SharedComponents/InputSimpleComponent";
import TickerTable from "./TickerComponents/TickerTableComponent";
function PullDataDays() {
  // Declare a new state variable, which we'll call "count"
  const [values, setValues] = useState("");
  const [ticker, setTicker] = React.useState("");
  const [days, setDays] = React.useState(10);
  function getTickerValues() {
    if (!ticker) {
      setValues("You need to input a ticker");
      return;
    }
    fetch(`/Alpaca/HistoricalTicker?ticker=${ticker}&days=${days}`)
      .then((result) => result.json())
      .then((output) => {
        console.log("Output: ", output);
        setValues(output);
      })
      .catch((err) => console.error(err));
  }
  return (
    <div>
      <div className="row">
        <div className="col-4">
          <InputSimple name="Ticker" type="text" setter={setTicker} value={ticker}></InputSimple>
          <InputSimple name="Days" type="number" setter={setDays} value={days}></InputSimple>
          <br />
          <button className="btn btn-primary" type="button" onClick={getTickerValues}>
            Get last {days} values
          </button>
        </div>
        <div className="col-8">
          {values && (
            <div>
              <h4>Results for {ticker}</h4>
              <TickerTable tickers={values} optional="Avg. 90 Days"></TickerTable>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export class PullPrices extends Component {
  static displayName = "Chiwan";
  render() {
    return <PullDataDays></PullDataDays>;
  }
}
