import React, { useState } from "react";
import InputSimple from "../SharedComponents/InputSimpleComponent";
import PolygonSmaTable from "../TickerComponents/PolygonSMATableComponent";

function SMA() {
    // Declare a new state variable, which we'll call "count"
    const [ticker, setTicker] = useState("");
    const [values, setValues] = useState("");
    const [loading, setLoading] = useState("");
    const [days, setDays] = React.useState(90);
    const [period, setPeriod] = React.useState(10);

    function getTickerValues() {
        setLoading("Loading...");
        setValues("");

        const tickers = [ticker]; // Replace `ticker` with an array of tickers if you want to query multiple tickers

        fetch(`/Polygon/GetSMA?tickers=${tickers.join(",")}&window=${days}&limit=${period}`)
            .then((result) => result.json())
            .then((output) => {
                console.log("Output: ", output);
                setValues(output);
                setLoading("");
            })
            .catch((err) => setLoading("Error: " + err));
    }



    return (
        <div className="row">
            <div className="col-4">
                <InputSimple name="Tickers (Separate by commas)" type="text" value={ticker} setter={setTicker}></InputSimple>
                <InputSimple name="Days" type="number" value={days} setter={setDays}></InputSimple>
                <InputSimple name="Period" type="number" value={period} setter={setPeriod}></InputSimple>
                <br />
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    <button className="btn btn-primary" type="button" onClick={getTickerValues}>
                        Get SMA
                    </button>
                </div>
            </div>
            <div className="col-8">
                {values && (
                    <div>
                        <h4>Results</h4>
                        <PolygonSmaTable smaDataList={values} />
                    </div>
                )}

                {(loading) && (
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "90%"
                    }}>
                        <div>
                            <i className="fa fa-spinner fa-spin fa-3x"></i>
                        </div>
                        <h4 style={{ display: "inline" }}>&nbsp;{loading}</h4>
                    </div>

                )}
            </div>
        </div>
    );
}
export default SMA;
