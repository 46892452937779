import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { PullPrices } from './components/PullPricesComponent';
import { Provider } from 'react-redux';
import { ConfigureStore } from './redux/configureStore';
import PullTickers from './components/PullTickersComponent'
import PullBollinger from './components/PullBollingerComponent';
import PullHourly from './components/PullHourlyComponent';
import Data from './components/DataComponent';
import 'font-awesome/css/font-awesome.min.css';
import './custom.css'
import SMA from './components/Pages/SMAComponent';

export default class App extends Component {
  static displayName = App.name;
  render() {
    const store = ConfigureStore();

    return (
      <Provider store={store}>
        <Layout>
          <Routes>
            <Route exact path='/' element={<PullPrices/>} />
            <Route path='/pullTickers' element={<PullTickers/>} />
            <Route path='/pullBollinger' element={<PullBollinger/>} />
            <Route path='/pullHourly' element={<PullHourly/>} />
            <Route path='/pullSMA' element={<SMA/>} />
            <Route path='/data/:ticker/:timeframe' element={<Data/>} />
            <Route path='/data' element={<Data/>} />
          </Routes>
        </Layout>
      </Provider>
    );
  }
}
