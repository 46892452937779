import React from "react";

function ProgressUpdate(props) {
  console.log(props);
  let source = props.source;
  console.log("SOURCE", source);
  if (source && source.completed) {
    return <div>Operation completed</div>
  }
  return (
    <div>
      <div>Operation running... </div>
      <div>Updated so far/Total to update </div>
      <div>{source.itemsUpdated}/{source.itemsToUpdate}</div>
      <div>Operation started: {source.startTime}</div>
      <div>Last updated: {source.lastUpdate}</div>
    </div>);
}

export default ProgressUpdate;
