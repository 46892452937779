import React from "react";

function InputSimple(props) {
  return <>
    <label>{props.name}:</label>
    <input
      className="form-control"
      type={props.type}
      onChange={(e) => {
        props.setter(e.target.value);
      }}
      value={props.value}
    />
  </>;
}
export default InputSimple;
