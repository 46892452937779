import React from "react";
import TickerTable from "./TickerTableComponent";

function PolygonSmaTable(props) {
  const smaDataList = props.smaDataList;

  const tickers = smaDataList.map((smaData) => {
    return {
      symbol: smaData.symbol,
      close: smaData.close ?? null,
      volume: smaData.volume ?? null,
      timeUtc: smaData.timeUtc ?? null,
    };
  });
  
  return (
    <TickerTable
      tickers={tickers}
      symbol={"Symbol"}
      close={"SMA"}
      showVolume={false}
      time={"Time"}
      optional={null}
      diffFieldEnabled={false}
    />
  );
}

export default PolygonSmaTable;
