import React, { useState } from "react";
import InputSimple from "./SharedComponents/InputSimpleComponent";
import TickerTable from "./TickerComponents/TickerTableComponent";
import PriceRangePicker from "./SharedComponents/PriceRangePickerComponent";
import ProgressUpdate from "./SharedComponents/ProgressUpdateComponent";

function PullHourly() {
  // Declare a new state variable, which we'll call "count"
  const [ticker, setTicker] = useState("");
  const [progress, setProgress] = useState("");
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState("");
  const [priceFrom, setPriceFrom] = React.useState(4);
  const [priceTo, setPriceTo] = React.useState(5);
  const [days, setDays] = React.useState(90);
  const [timeFrameMinutes, setTimeFrameMinutes] = React.useState(60);
  const [period, setPeriod] = React.useState(10);
  const [diff, setDiff] = React.useState(0.05);
  // &ticker=${ticker}
  function getTickerValues() {
    setLoading("Loading...");
    setValues("");
    fetch(`/Alpaca/FilterBollinger?timeFrameMinutes=${timeFrameMinutes}&days=${days}&period=${period}&diff=${diff}&priceFrom=${priceFrom}&priceTo=${priceTo}&ticker=${ticker}`)
      .then((result) => result.json())
      .then((output) => {
        console.log("Output: ", output);
        setValues(output);
        setLoading("");
      })
      .catch((err) => setLoading("Error: " + err));
  }

  function peekProgress() {
    console.log("Checking progress...");
    setLoading("Loading...");

    fetch(`/Alpaca/PeekProgress`)
      .then((result) => result.json())
      .then((output) => {
        console.log(output);
        if (output == null)
        {
          setProgress({completed: true});
          console.log("No operation in progress")
        }
        else {
          setProgress(output);
          setTimeout(function () { peekProgress(); }, 5000);
        }

        setLoading("");
      })
      .catch((err) => setLoading("Error: " + err));
  }
  function updateTickerValues() {
    setValues("");
    setTimeout(function () { peekProgress(); }, 3000);
    setProgress({itemsToUpdate:"?",itemsUpdated:"0"});
    fetch(`/Alpaca/UpdateAllTickers?TimeFrameMinutes=${timeFrameMinutes}`).catch((err) => setLoading("Error: " + err));
  }
  return (
    <div className="row">
      <div className="col-4">
        <InputSimple name="Tickers (Separate by commas)" type="text" value={ticker} setter={setTicker}></InputSimple>
        <InputSimple name="Period" type="number" value={days} setter={setDays}></InputSimple>
        <InputSimple name="Last" type="number" value={period} setter={setPeriod}></InputSimple>
        <InputSimple name="Timeframe (In Minutes 1, 60 or 1440)" type="number" value={timeFrameMinutes} setter={setTimeFrameMinutes}></InputSimple>
        <InputSimple name="Max. Diff." type="number" value={diff} setter={setDiff}></InputSimple>
        <label>Price </label>
        <PriceRangePicker a={priceFrom} aSetter={setPriceFrom} b={priceTo} bSetter={setPriceTo}></PriceRangePicker>
        <br />
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <button className="btn btn-primary" type="button" onClick={getTickerValues}>
            Get Average Values
          </button>
          <button className="btn btn-secondary" type="button" onClick={updateTickerValues}>
            Update all tickers
          </button>
        </div>
        <div>
          { }
        </div>

      </div>
      <div className="col-8">
        {values && (
          <div>
            <h4>Results</h4>
            <TickerTable tickers={values} volume="Avg. Dif" diffFieldEnabled={true}></TickerTable>
          </div>
        )}

        {(progress || loading) && (
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90%"
          }}>
            <div className="spinner-grow" style={{ width: "3rem", height: "3rem" }} role="status">
              <span className="sr-only"></span>
            </div>
            <ProgressUpdate source={progress}></ProgressUpdate>
            <h4 style={{ display: "inline" }}>&nbsp;{loading}</h4>
          </div>
        )}
      </div>
    </div>
  );
}
export default PullHourly;
